import { Tuple, configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import writingProgressReducer from "./writing-progress-slice";
import loadingStateReducer from "./loading-slice";
import draftsReducer from "./drafts-slice";
import scheduledReducer from "./scheduled-slice";
import postedReducer from "./posted-slice";
import engagementListReducer from "./engagement-list-slice";
import postIdeasListReducer from "./post-ideas-slice";

const store = configureStore({
  reducer: {
    writingProgress: writingProgressReducer,
    loadingState: loadingStateReducer,
    drafts: draftsReducer,
    scheduled: scheduledReducer,
    posted: postedReducer,
    engagementList: engagementListReducer,
    postIdeasList: postIdeasListReducer
  },
  middleware: () => new Tuple(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
