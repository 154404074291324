export const homePath = "/";
export const termsConditionsPath = "/terms-conditions";
export const privacyPolicyPath = "/privacy";
export const pricingPath = "/pricing";
export const paymentFailedPath = "payment-failed";
export const accountPath = "/account";
export const interviewPath = "/account/interview";
export const writePostPath = "/dashboard/write-post";
export const scheduledPostsPath = "/dashboard/scheduled";
export const postedPath = "/dashboard/posted";
export const draftsPath = "/dashboard/drafts";
export const engagementListPath = "/dashboard/engagement-list";
export const schedulePath = "/dashboard/schedule";
