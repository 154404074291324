import React from "react";
import { LogoIcon } from "./svgs";

const Logo = () => {
  return (
    <div className="flex items-center gap-1 w-fit">
      <LogoIcon className="text-xl" />
      <p className="text-lg font-medium w-fit">TopVoice</p>
    </div>
  );
};

export default Logo;
