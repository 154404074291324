import React from "react";
import Link from "next/link";
import { signIn, useSession } from "next-auth/react";

import { Button } from "@/components/ui/button";
import { writePostPath } from "@/lib/utils/paths";
import { ChevronRight } from "lucide-react";

interface IMainButtonProps {
  hasArrow?: boolean;
  size?: "default" | "sm" | "lg" | "icon" | null | undefined;
}
const MainButton = ({ hasArrow, size }: IMainButtonProps) => {
  const { data: session } = useSession();

  return session ? (
    <Button
      size={size}
      className="text-base bg-foreground hover:bg-muted-foreground"
    >
      <Link
        href={writePostPath}
        shallow
        className="flex items-center justify-center gap-2"
      >
        Go to dashboard {hasArrow && <ChevronRight size={"md"} />}
      </Link>
    </Button>
  ) : (
    <Button
      size={size}
      onClick={() => signIn("linkedin")}
      className="text-base bg-foreground hover:bg-muted-foreground gap-2"
    >
      Write your first post! {hasArrow && <ChevronRight />}
    </Button>
  );
};

export default MainButton;
