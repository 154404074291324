import { createSlice } from "@reduxjs/toolkit";


export interface IPostIdeasListState {
  loaded: boolean;
  postIdeas: string[];
}

const initialState: IPostIdeasListState = {
  loaded: false,
  postIdeas: [],
};

export const postIdeasListSlice = createSlice({
  name: "post-ideas-list-slice",
  initialState,
  reducers: {
    setPostIdeas: (
      state: IPostIdeasListState,
      action: { payload: { postIdeas: string[] } }
    ) => {
      state.postIdeas = action.payload.postIdeas;
      state.loaded = true;
    },
    addPostIdeas: (
      state: IPostIdeasListState,
      action: { payload: { postIdeas: string[] } }
    ) => {
      state.postIdeas = state.postIdeas.concat(action.payload.postIdeas);
    },
    removePostIdea: (
      state: IPostIdeasListState,
      action: { payload: { index: number } }
    ) => {
      state.postIdeas.splice(action.payload.index, 1);
    },
  },
});

export const {
    addPostIdeas,
    removePostIdea,
    setPostIdeas
} = postIdeasListSlice.actions;

export default postIdeasListSlice.reducer;
