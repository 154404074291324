
import { createSlice } from "@reduxjs/toolkit";
import { TContentTag, TPostImage } from "@repo/database";
import { TScheduledPost } from "@repo/database/dist/content/scheduled-content/scheduled-content-model";

export interface IScheduledState {
  loaded: boolean;
  posts: TScheduledPost[];
}

const initialState: IScheduledState = {
  loaded: false,
  posts: [],
};

export const scheduledSlice = createSlice({
  name: "scheduled-slice",
  initialState,
  reducers: {
    setPosts: (
      state: IScheduledState,
      action: { payload: { posts: TScheduledPost[] } }
    ) => {
      state.posts = action.payload.posts;
      state.loaded = true;
    },
    addPosts: (
      state: IScheduledState,
      action: { payload: { posts: TScheduledPost[] } }
    ) => {
      state.posts = state.posts.concat(action.payload.posts);
    },
    editPost: (
      state: IScheduledState,
      action: {
        payload: { index: number; newContent: TContentTag, newComment?: TContentTag };
      }
    ) => {
      const post = state.posts[action.payload.index]!;
      post.text = action.payload.newContent.text;
      post.tags = action.payload.newContent.tags;
      post.comment = action.payload.newComment;
    },
    setPostImage: (
      state: IScheduledState,
      action: {
        payload: { index: number; image?: TPostImage };
      }
    ) => {
      const post = state.posts[action.payload.index]!;
      post.image = action.payload.image;
    },
    udpatePostIndex: (
      state: IScheduledState,
      action: {
        payload: { currentIndex: number; newIndex: number };
      }
    ) => {
      const {currentIndex, newIndex} = action.payload
      const newPosts = [...state.posts]
      const [post] = newPosts.splice(currentIndex, 1);
      newPosts.splice(newIndex, 0, post!);      
      return {
       ...state,
        posts: newPosts
      }
    },
    removePost: (
      state: IScheduledState,
      action: { payload: { index: number } }
    ) => {
      state.posts.splice(action.payload.index, 1);
    },
  },
});

export const {
  setPosts,
  addPosts,
  editPost,
  setPostImage,
  removePost,
  udpatePostIndex
} = scheduledSlice.actions;

export default scheduledSlice.reducer;
