import { accountPath } from "@/lib/utils/paths";
import React from "react";
import { Button } from "../ui/button";
import { useSession } from "next-auth/react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import Link from "next/link";
import { cn } from "@/lib/utils/cn";

interface IProps {
  type: "always-extended" | "can-shrink";
  onClick?: () => void;
}

const AccountButton = ({ type, onClick }: IProps) => {
  const { data: session } = useSession();
  if (!session?.user) return;
  return (
    <Link href={accountPath} shallow onClick={onClick}>
      <Button
        variant="secondary"
        className={cn([
          "rounded-full px-0 gap-3",
          type === "can-shrink" ? "custom-button" : "",
        ])}
      >
        {session?.user.linkedinAccount && (
          <Avatar>
            <AvatarImage src={session.user.linkedinAccount.picture} />
            <AvatarFallback className="border border-foreground p-2">
              {`${session.user.linkedinAccount.given_name[0]}${session.user.linkedinAccount.family_name[0]}`}
            </AvatarFallback>
          </Avatar>
        )}
        <div
          className={cn([
            "text-start mr-3",
            type === "can-shrink" ? "hide-on-small" : "",
          ])}
        >
          <p>{session.user.linkedinAccount.name}</p>
          <p className="text-xs text-yellow-600">
            {session.user.availableTokens.toFixed(2)} tokens
          </p>
        </div>
      </Button>
    </Link>
  );
};

export default AccountButton;
