import { createSlice } from "@reduxjs/toolkit";
import { TContentTag, TPostImage } from "@repo/database";


export type TProgressSource = "outline" | "seed" | "repurpose"

type TExtendedTPostImage = TPostImage & {
  file: File | null
}

export type IWritingProgress = {
  [key in TProgressSource]: {
    content: TContentTag,
    image?: TExtendedTPostImage,
    comment: TContentTag
  }
}

const initialState: IWritingProgress = {
    outline: {
        content: {
          text: "",
          tags: []
        },
        image: undefined,
        comment: {
          text: "",
          tags: []
        }
    },
    repurpose: {
      content: {
        text: "",
        tags: []
      },
      image: undefined,
      comment: {
        text: "",
        tags: []
      }
  },
    seed: {
      content: {
        text: "",
        tags: []
      },
      image: undefined,
      comment: {
        text: "",
        tags: []
      }
  }
};

export const writingProgressSlice = createSlice({
  name: "writing-progress-slice",
  initialState,
  reducers: {
    setWritingProgress: (
      state: IWritingProgress,
      action: { payload: { content: TContentTag, source: TProgressSource } }
    ) => {
        const {content, source} = action.payload
        state[source].content = content
    },
    setCommentProgress: (
      state: IWritingProgress,
      action: { payload: { comment: TContentTag, source: TProgressSource } }
    ) => {
        const {comment, source} = action.payload
        state[source].comment = comment
    },
    setImage: (
      state: IWritingProgress,
      action: { payload: { source: TProgressSource, image?: TExtendedTPostImage } }
    ) => {
        const {source, image} = action.payload
        state[source].image = image
    },
  },
});

export const {
  setWritingProgress,
  setImage,
  setCommentProgress
} = writingProgressSlice.actions;

export default writingProgressSlice.reducer;
