import { createSlice } from "@reduxjs/toolkit";
import { TPostedPost } from "@repo/database";

export interface IPostedState {
  loaded: boolean;
  posts: TPostedPost[];
}

const initialState: IPostedState = {
  loaded: false,
  posts: [],
};

export const scheduledSlice = createSlice({
  name: "posted-slice",
  initialState,
  reducers: {
    setPosts: (
      state: IPostedState,
      action: { payload: { posts: TPostedPost[] } }
    ) => {
      state.posts = action.payload.posts;
      state.loaded = true;
    },
    addPosts: (
      state: IPostedState,
      action: { payload: { posts: TPostedPost[] } }
    ) => {
      state.posts = state.posts.concat(action.payload.posts);
    },
  },
});

export const {
  setPosts,
  addPosts,
} = scheduledSlice.actions;

export default scheduledSlice.reducer;
