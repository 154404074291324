import { createSlice } from "@reduxjs/toolkit";
import { TEngagementListUser } from "@repo/database/dist/engagement-list/engagement-list-model";

export interface IEngagementListState {
  loaded: boolean;
  users: TEngagementListUser[];
}

const initialState: IEngagementListState = {
  loaded: false,
  users: [],
};

export const engagementListSlice = createSlice({
  name: "engagement-list-slice",
  initialState,
  reducers: {
    setUsers: (
      state: IEngagementListState,
      action: { payload: { users: TEngagementListUser[] } }
    ) => {
      state.users = action.payload.users;
      state.loaded = true;
    },
    addUsers: (
      state: IEngagementListState,
      action: { payload: { users: TEngagementListUser[] } }
    ) => {
      state.users = state.users.concat(action.payload.users);
    },
    removeUser: (
      state: IEngagementListState,
      action: { payload: { index: number } }
    ) => {
      state.users.splice(action.payload.index, 1);
    },
  },
});

export const {
  setUsers,
  addUsers,
  removeUser
} = engagementListSlice.actions;

export default engagementListSlice.reducer;
