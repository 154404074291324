import { createSlice } from "@reduxjs/toolkit";

type IState = {
  isLoading: boolean
}

const initialState: IState = {
    isLoading: false
};

export const loadingSlice = createSlice({
  name: "loading-slice",
  initialState,
  reducers: {
    setLoadingState: (
      state: IState,
      action: { payload: boolean }
    ) => {
        const {payload} = action
        state.isLoading = payload
    },
  },
});

export const {
  setLoadingState
} = loadingSlice.actions;

export default loadingSlice.reducer;
