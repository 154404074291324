import { AppDispatch, RootState } from "@/store";
import { setLoadingState } from "@/store/loading-slice";
import { useDispatch, useSelector } from "react-redux";

const useLoading = () => {
  const { isLoading } = useSelector((state: RootState) => state.loadingState);
  const dispatch = useDispatch<AppDispatch>();

  const setIsLoading = (value: boolean) => {
    dispatch(setLoadingState(value));
  };

  return {
    isLoading,
    setIsLoading,
  };
};

export default useLoading;
