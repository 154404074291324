import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { api } from "@/lib/utils/api";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { NextComponentType } from "next";
import { Dashboard } from "@/layouts/dashboard";
import { Toaster } from "@/components/ui/toaster";

type MyAppProps = AppProps & {
  Component: NextComponentType & { useLayout?: boolean; layoutTitle?: string };
};

const App: React.FC<MyAppProps> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  const useLayout = Component.useLayout || false;
  const layoutTitle = Component.layoutTitle || "";

  if (useLayout) {
    return (
      <SessionProvider session={session}>
        <NextThemesProvider
          attribute="class"
          defaultTheme="system"
          enableSystem
          disableTransitionOnChange
        >
          <Dashboard title={layoutTitle}>
            <Component {...pageProps} />
          </Dashboard>
          <Toaster />
        </NextThemesProvider>
      </SessionProvider>
    );
  }

  return (
    <SessionProvider session={session}>
      <NextThemesProvider
        attribute="class"
        defaultTheme="system"
        enableSystem
        disableTransitionOnChange
      >
        <Component {...pageProps} />
        <Toaster />
      </NextThemesProvider>
    </SessionProvider>
  );
};

export default api.withTRPC(App);
