import { createSlice } from "@reduxjs/toolkit";
import { TContentTag, TDraftPost, TPostImage } from "@repo/database";

export interface IDraftsState {
  loaded: boolean;
  posts: TDraftPost[];
}

const initialState: IDraftsState = {
  loaded: false,
  posts: [],
};

export const draftsSlice = createSlice({
  name: "drafts-slice",
  initialState,
  reducers: {
    setPosts: (
      state: IDraftsState,
      action: { payload: { posts: TDraftPost[] } }
    ) => {
      state.posts = action.payload.posts;
      state.loaded = true;
    },
    addPosts: (
      state: IDraftsState,
      action: { payload: { posts: TDraftPost[] } }
    ) => {
      state.posts = state.posts.concat(action.payload.posts);
    },
    editPost: (
      state: IDraftsState,
      action: {
        payload: { index: number; newContent: TContentTag, newComment?: TContentTag };
      }
    ) => {
      const post = state.posts[action.payload.index]!;
      post.text = action.payload.newContent.text;
      post.tags = action.payload.newContent.tags;
      post.comment = action.payload.newComment;
    },
    setPostImage: (
      state: IDraftsState,
      action: {
        payload: { index: number; image?: TPostImage };
      }
    ) => {
      const post = state.posts[action.payload.index]!;
      post.image = action.payload.image;
    },
    removePost: (
      state: IDraftsState,
      action: { payload: { index: number } }
    ) => {
      state.posts.splice(action.payload.index, 1);
    },
  },
});

export const {
  setPosts,
  addPosts,
  setPostImage,
  editPost,
  removePost,
} = draftsSlice.actions;

export default draftsSlice.reducer;
