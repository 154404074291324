import React from "react";
import { AlertDialog, AlertDialogContent } from "../ui/alert-dialog";
import useLoading from "@/hooks/useLoading";

const LoadingModal = () => {
  const { isLoading, setIsLoading } = useLoading();

  return (
    <AlertDialog open={isLoading} onOpenChange={setIsLoading}>
      <AlertDialogContent>Loading...</AlertDialogContent>
    </AlertDialog>
  );
};

export default LoadingModal;
