import Link from "next/link";
import { Menu } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { ReactNode, useState } from "react";
import Logo from "../components/logo";
import {
  draftsPath,
  engagementListPath,
  homePath,
  postedPath,
  schedulePath,
  scheduledPostsPath,
  writePostPath,
} from "@/lib/utils/paths";
import { useSession } from "next-auth/react";
import { ModeToggle } from "./theme-toggle";
import AccountButton from "@/components/layout/account-button";
import { Provider } from "react-redux";
import store from "@/store";
import LoadingModal from "@/components/functional/loading-modal";
import HomeNavbar from "@/components/pages/home/navbar";

interface IProps {
  children: ReactNode;
  title: string;
}

interface ILink {
  href: string;
  text: string;
}

const Links: ILink[] = [
  {
    href: writePostPath,
    text: "Write a post",
  },
  {
    href: draftsPath,
    text: "Drafts",
  },
  {
    href: scheduledPostsPath,
    text: "Scheduled",
  },
  {
    href: postedPath,
    text: "Posted",
  },
  {
    href: schedulePath,
    text: "Schedule",
  },
];

export function Dashboard({ children, title }: IProps) {
  const { data: session } = useSession();
  const [isOpen, setIsOpen] = useState(false);

  const handleMobileMenuOpen = () => {
    setIsOpen(true);
  };
  const handleMobileMenuClose = () => {
    setIsOpen(false);
  };

  const content = (
    <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
      <div className="mx-auto w-full max-w-6xl gap-2">
        <h1 className="text-3xl font-semibold">{title}</h1>
      </div>
      <div className="mx-auto w-full max-w8xl">{children}</div>
    </main>
  );

  if (!session?.user)
    return (
      <Provider store={store}>
        <HomeNavbar showFeatures={false} />
        {content}
      </Provider>
    );
  return (
    <Provider store={store}>
      <div className="flex min-h-screen w-full flex-col">
        <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 z-50">
          <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
            <Link
              href={homePath}
              className="flex items-center gap-2 text-lg font-semibold md:text-base w-fit"
              shallow
            >
              <Logo />
            </Link>
            {Links.map((link, index) => (
              <Link
                key={index}
                href={link.href}
                className="text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap"
                shallow
              >
                {link.text}
              </Link>
            ))}
            <ModeToggle />
          </nav>
          <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
              onClick={handleMobileMenuOpen}
            >
              <Menu className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>

            <SheetContent side="left">
              <nav className="grid gap-6 text-lg font-medium">
                <Link
                  href={homePath}
                  className="w-fit"
                  onClick={handleMobileMenuClose}
                  shallow
                >
                  <Logo />
                </Link>
                {Links.map((link, index) => (
                  <Link
                    key={index}
                    href={link.href}
                    className="text-muted-foreground hover:text-foreground"
                    onClick={handleMobileMenuClose}
                    shallow
                  >
                    {link.text}
                  </Link>
                ))}
                <ModeToggle />
                <div className="mt-8">
                  <AccountButton
                    type="always-extended"
                    onClick={handleMobileMenuClose}
                  />
                </div>
              </nav>
            </SheetContent>
          </Sheet>
          <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
            <div className="flex-1 flex flex-row justify-center">
              <div className="md:hidden">
                <Link href={homePath} shallow>
                  <Logo />
                </Link>
              </div>
            </div>
            <AccountButton type="can-shrink" />
          </div>
        </header>
        {content}
      </div>
      <LoadingModal />
    </Provider>
  );
}
